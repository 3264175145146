<template>
  <v-form
    @submit.prevent="title === 'Crear' ? createSpecialty() : editSpecialty()"
    v-model="validation"
    ref="form"
  >
    <v-container>
      <Title :title="title + ' especialidad'" class="mb-8" />
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.description"
            type="text"
            label="Especialidad"
            class="my-1"
            prepend-icon="mdi-stethoscope"
            :rules="rules.default"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="text--right">
      <v-btn
        small
        color="primary"
        class="pa-5"
        type="submit"
        :disabled="!validation"
        :loading="loading"
      >
        {{ title }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import Title from "../Title.vue";

export default {
  name: "SpecialtyForm",
  components: {
    Title
  },
  data() {
    return {
      title: "Crear", // Crear | Editar
      form: {
        id: "",
        description: "",
      },
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
      },
      loading: false,
      validation: true,
    };
  },
  methods: {
    ...mapMutations(["setAlert", "pushSpecialty", "updateSpecialty"]),
    setFormValues({ form }) {
      this.form.id = form?.id || null;
      this.form.description = form?.description || "";
    },
    async createSpecialty() {
      try {
        this.loading = true;
        const res = await axios.post("/api/specialty", this.form);
        const { specialty } = res.data;
        this.pushSpecialty(specialty);
        const alert = {
          show: true,
          timeout: 4000,
          color: "success",
          icon: "mdi-check-circle",
          text: "Especialidad creada correctamente",
        };
        this.setAlert(alert);
        this.$emit("created", { item: specialty });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          timeout: 4000,
          icon: "mdi-alert-remove",
          text: error.respnse.data.message,
        };
        this.setAlert(errorAlert);
      } finally {
        this.loading = false;
      }
    },
    async editSpecialty() {
      try {
        this.loading = true;
        const res = await axios.put(
          `/api/specialty/${this.form.id}`,
          this.form
        );
        const { specialty } = res.data;
        this.updateSpecialty(specialty);
        const alert = {
          show: true,
          color: "dark_primary",
          timeout: 4000,
          icon: "mdi-check-circle",
          text: "Especialidad actualizada correctamente",
        };
        this.setAlert(alert);
        this.$emit("updated", { item: specialty });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
