<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Especialidades</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <v-row>
          <v-col md="8" cols="12">
            <v-btn
              title="Crear nuevo"
              color="primary"
              class="mt-2"
              small
              depressed
              @click="handleClickNew"
            >
              nuevo
            </v-btn>
          </v-col>
        </v-row>

        <p class="caption pasive--text text-right">
          {{ items.length }} especialidades
        </p>

        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="items.length"
          disable-sort
          no-data-text="Debe agregar una especialidad..."
          item-class="text-center"
          class="my-5"
          hide-default-footer
        >
          <template slot="item.updatedAt" scope="props">
            {{ formatDateAndTime(props.item.updatedAt) }}
          </template>

          <template slot="item.action" scope="props">
            <div class="d-flex justify-center my-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    small
                    icon
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleClickEdit(props.item)"
                  >
                    <v-icon small> mdi-square-edit-outline </v-icon>
                  </v-btn>
                </template>
                <span class="caption">Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    small
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                    @click="showDeleteWarning(props.item)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span class="caption">Eliminar</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showForm"
      width="900"
      persistent
      @keydown.esc="showForm = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <SpecialtyForm
          ref="SpecialtyFormRef"
          @created="showForm = false"
          @updated="showForm = false"
        />
      </v-card>
    </v-dialog>

    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteOne"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SearchInput from "../components/SearchInput.vue";
import SpecialtyForm from "../components/Specialty/SpecialtyForm.vue";
import WarningDialog from "../components/WarningDialog.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "Especialties",
  components: {
    SearchInput,
    SpecialtyForm,
    WarningDialog,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      deleting: false,
      headers: [
        { text: "ID", value: "id", align: "center" },
        { text: "Descripción", value: "description", align: "left" },
        { text: "Última actualización", value: "updatedAt", align: "left" },
        { text: "Acciones", value: "action", align: "center" },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser", "specialties"]),
    items() {
      return this.specialties || [];
    },
  },
  methods: {
    ...mapMutations(["setAlert", "removeSpecialty"]),
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },

    handleClickNew() {
      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.SpecialtyFormRef.title = "Crear";
        this.$refs.SpecialtyFormRef.setFormValues({ form: null });
      });
    },

    handleClickEdit(row) {
      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.SpecialtyFormRef.title = "Editar";
        this.$refs.SpecialtyFormRef.setFormValues({ form: row });
      });
    },

    showDeleteWarning(item) {
      this.item = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea remover "${item.description}?`;
      this.$refs.WarningDialogRef.show = true;
    },

    async deleteOne() {
      try {
        this.deleting = true;

        const res = await axios.delete(`/api/specialty/${this.item.id}`);
        const { message } = res.data;

        if (message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            timeout: 4000,
            text: message,
          });
        }

        this.removeSpecialty(this.item);

        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
      }
    },

    handleSearchInput(data) {
      this.terms = data;
      this.getData();
    },
  },
};
</script>
